import axios from 'axios';
import { isBrowser, isAuthenticated, getAccessToken } from './auth.service';

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let user = false;

/**
 *
 *
 *
 **/
const fetchUser = async () => {
  console.log('fetchUser')
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/user/me`;
  const bearer = 'Bearer ' + getAccessToken();

  let result;
  try {
    result = await axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': bearer }
    });
  } catch(err) {
    console.warn('Error fetching user', err);
    return null;
  }
  if (result) { user = result.data }

  return result;
}

/**
 *
 *
 *
 **/
const updateUser = async (data) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  const url = `${process.env.GATSBY_M101_API_URL}/user`;
  const bearer = 'Bearer ' + getAccessToken();
  console.log('Update user data', data);

  return axios.put(url, data, {
    compress: false,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearer
    }
  });
}

/**
 *
 *
 *
 **/
const updateAvatar = async (file) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  const url = `${process.env.GATSBY_M101_API_URL}/user/avatar`;
  const bearer = 'Bearer ' + getAccessToken();
  console.log('[user.service] Update avatar ', file);

  if (!file) { return Promise.reject('No file'); }

  try {
    const formData = new FormData();
    formData.append('avatar', file);

    await axios.post(url, formData, {
      headers: {
        'Authorization': bearer,
        'Content-Type': 'multipart/form-data'
      }
    });

  } catch(err) {
    console.warn(err);
    return false;
  }

  return true;
}

/**
 *
 *
 *
 **/
const getUserInfo = () => {
  return user;
}

export {
  fetchUser,
  updateUser,
  updateAvatar,
  getUserInfo
}
