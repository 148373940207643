// reference: https://github.com/gatsbyjs/store.gatsbyjs.org/blob/master/src/utils/auth.js
import auth0 from 'auth0-js';
import { fetchUser } from './user.service';

export const isBrowser = typeof window !== 'undefined';

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let profile = false;

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

// Only instantiate Auth0 if we’re in the browser.
const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_M101_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_M101_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_M101_AUTH0_CALLBACK,
      audience: process.env.GATSBY_M101_AUTH0_AUDIENCE,
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {};


export const login = () => {
  if (!isBrowser) { return }

  auth.authorize()
}

export const signup = () => {
  if (!isBrowser) { return }

  auth.authorize({ mode: 'signUp', initialScreen: 'signUp' });
}

export const Logout = () => {
  if (!isBrowser) { return }

  localStorage.setItem('isLoggedIn', false);
  profile = false;

  const { protocol, host } = window.location;
  const returnTo = `${protocol}//${host}`;

  auth.logout({ returnTo });
}

// Set a valid session
const setSession = callback => (err, authResult) => {
  if (!isBrowser) { return; }

  if (err) {
    console.warn(err);
    Logout();
    callback();
    return;
  }
  if (authResult && authResult.accessToken && authResult.idToken) {
    // Check email validated
    // if (authResult.idTokenPayload.email_verified) {
    //   navigate('/verify-email');
    // }
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;
    // console.log(expiresAt);
    profile = authResult.idTokenPayload;
    // console.log(profile)
    localStorage.setItem('isLoggedIn', true);

    fetchUser()
      .then(callback)
      .catch(err => {
        console.warn(err);
        callback(null);
      });

  } else {
    console.warn('setsesion = auth nto set');
  }
}

// Entry point for app. Defined in gatsby-browser.js
export const silentAuth = callback => {
  if (!isBrowser) { return; }
  // Return null if user is not authenticated
  if (!isAuthenticated()) {
    return callback()
  }
  // Check the session using the ManagementClient, set the result in the app session.
  // auth0.checkSession({}, function (err, authResult) {
  auth.checkSession({}, setSession(callback))

}

export const handleAuthentication = (callback = () => {}) => {
  if (!isBrowser) { return; }
  // console.log('[ auth.service ] handleAuthentication')
  auth.parseHash(setSession(callback));
}

// Check if user is authenticated (authresult in localstorage is valid)
export const isAuthenticated = () => {
  if (!isBrowser) { return; }

  return (localStorage.getItem('isLoggedIn') === 'true');
}

export const getIdToken = () => {
  if (!isBrowser) { return ''; }
  return tokens.idToken;
}

export const getAccessToken = () => {
  if (!isBrowser) { return ''; }
  return tokens.accessToken;
}

export const getAuthInfo = () => {
  return profile
}
